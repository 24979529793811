import React from 'react'
import _ from 'lodash'
import CFTurnstile from 'react-cf-turnstile-jk'

import { markdownify } from '../utils'
import FormField from './FormField'

export default class SectionForm extends React.Component {
  render() {
    let section = _.get(this.props, 'section', null)
    return (
      <section
        id={_.get(section, 'section_id', null)}
        className='block block-form'>
        {_.get(section, 'title', null) && (
          <h2 className='block-title underline inner-sm'>
            {_.get(section, 'title', null)}
          </h2>
        )}
        <div className='block-content inner-sm'>
          {_.get(section, 'content', null) &&
            markdownify(_.get(section, 'content', null))}
          <form
            name={_.get(section, 'form_id', null)}
            id={_.get(section, 'form_id', null)}
            {...(_.get(section, 'form_action', null)
              ? { action: _.get(section, 'form_action', null) }
              : null)}
            method='POST'>
            <div className='screen-reader-text'>
              <label>
                Don't fill this out if you're human:{' '}
                <input aria-label='bot' name='bot-field' />
              </label>
            </div>
            <input
              aria-label='bot'
              type='hidden'
              name='form-name'
              value={_.get(section, 'form_id', null)}
            />
            {_.map(_.get(section, 'form_fields', null), (field, field_idx) => (
              <FormField key={field_idx} {...this.props} field={field} />
            ))}
            <CFTurnstile
              sitekey='0x4AAAAAAAklqI3vYgqXiynG'
              callback={(token) => {
                console.log('token:', token)
              }}
            />
            <div className='form-submit'>
              <button type='submit' className='button'>
                {_.get(section, 'submit_label', null)}
              </button>
            </div>
          </form>
        </div>
      </section>
    )
  }
}
